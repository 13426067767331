<template>
  <b-card title="COMING SOON - Awesome File Explorer 🙌">
    <img src="file_explorer.png" style="width:100%" />
  </b-card>
</template>

<script>
import { BCard, BCardText } from 'bootstrap-vue'
import VueI18n from 'vue-i18n'

export default {
  components: {
    BCard,
    BCardText,
  },
}
</script>

<style>

</style>
